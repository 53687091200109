import { SLOT_TIME_FORMAT } from "@/_lib/utils/time";
import { HISTORY_TIME_ZONE, MILITARY } from "@/viewer/data/constants";
import { format, parse } from "date-fns";
import { DateTime } from "luxon";
import tzTokenizeDate from "../../../vendor/date-fns-tz/_lib/tzTokenizeDate/index";

const getDBTimezone = (): string => {
  const { LbsAppData } = window as any;

  return LbsAppData.User.get("tz");
};

export const getBrowserTimezone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const isTZAwarenessEnabled = (): boolean => {
  const { LbsAppData } = window as any;

  return LbsAppData.User.attributes.parameters.LBLiteTimeZoneAwareness;
};

export const getChosenTimezone = (): string => {
  return isTZAwarenessEnabled() ? getBrowserTimezone() : getDBTimezone();
};

export const isTimezoneAwarenessEnabledAndOrIsUserInDifferentTimezone = (): boolean => {
  if (!isTZAwarenessEnabled()) return false;

  const browserTimezone = getBrowserTimezone();
  const dbTimezone = getDBTimezone();

  //Timezone awareness is enabled, and the user is not in the db timezone
  //meaning we should actually care about timezone awareness
  if (browserTimezone !== dbTimezone) return true;

  // Otherwise, the only remaining option is timezone awareness is enabled
  // and the db and user are in the same timezone

  return false;
};
// Convert a date string from a given timezone to another timezone
const convertTz = (d: Date | string | number, fromTz: string, toTz: string) => {
  // tokens are: year, month, day, hour, minute, second
  // month is subtracted by 1 since it is 0 indexed
  if (!d) return new Date();

  const dateObject = d instanceof Date ? d : new Date(d);
  const dateTime = DateTime.fromJSDate(dateObject);
  const test = dateTime.setZone(fromTz, { keepLocalTime: true });
  const testConversion = test.setZone(toTz);

  const tokens: [number, number, number, number, number, number] = tzTokenizeDate(testConversion, toTz);

  return new Date(tokens[0], tokens[1] - 1, tokens[2], tokens[3], tokens[4], tokens[5]);
};

/**
 * Given an input time, return a Date corresponding to this time in the appropriate timezone (per the settings)
 */
export const timezoneConverter = (inputTime: string | Date, dbSubmit = false): Date | null => {
  if (!inputTime) return null;

  const dbTimezone = getDBTimezone();
  const tz = getChosenTimezone();

  if (dbSubmit) {
    // Convert from default timezone to db timezone
    return convertTz(inputTime, tz, dbTimezone);
  }

  // Convert from db timezone to (explicit) chosen timezone
  return convertTz(inputTime, dbTimezone, tz);
};

/**
 * Convert a time from the HISTORY_TIME_ZONE to the chosen one.
 */
export const convertSlotHistoryTimestamp = (inputTime: string | Date): Date => {
  return convertTz(inputTime, HISTORY_TIME_ZONE, getChosenTimezone());
};

export const isMilitaryTimePreferred = (): boolean => {
  const { LbsAppData } = window as any;

  return LbsAppData.User.get("time_display") === MILITARY;
};

/**
 * Given a time in h:mm a format, correct the timezone with timezoneConverter, then format the result,
 * using 24-hour time if requested and replacing "am" and "pm" with "[[AM]]" and "[[PM]]" for whatever reason.
 */
const correctTime = (s: string, fmt = "h:mm a"): string => {
  const d = parse(s, fmt, new Date());
  const converted = timezoneConverter(d);

  if (!converted) return "";

  let outFmt = fmt;

  if (isMilitaryTimePreferred()) {
    outFmt = "HH:MM";
  }

  return format(converted, outFmt).replace("am", "[[AM]]").replace("pm", "[[PM]]");
};

interface SlotTZConversionOpts {
  times: string[];
  formatString: string;
  forceConversion?: boolean;
  fromFormatString?: string;
}

export const getTimeBasedOnSlotTimesTZConversionState = (opts: SlotTZConversionOpts): string[] => {
  const { times, formatString, forceConversion = false, fromFormatString = SLOT_TIME_FORMAT } = opts;

  return times.map((time) => {
    if (!isTimezoneAwarenessEnabledAndOrIsUserInDifferentTimezone() && !forceConversion) {
      return DateTime.fromFormat(time, fromFormatString).toFormat(formatString);
    }

    return DateTime.fromJSDate(timezoneConverter(time) as Date).toFormat(formatString);
  });
};

/**
 * Convert the ('hh:mm a'-formatted) times in a block of text to the appropriate ambient timezone.
 * Port of TimeHelper.fullStringConversion.
 */
export const fullStringConversion = (inputString: string): string => {
  const timeRegex = /((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/g;

  return inputString.replaceAll(timeRegex, (t) => correctTime(t));
};

export const setUrlWithTimezone = (): void => {
  const { LbsAppData } = window as any;

  if (LbsAppData && LbsAppData.User) {
    const tzEnabled = LbsAppData.User.attributes.parameters.LBLiteTimeZoneAwareness;
    const tz = getBrowserTimezone();
    const url_string = window.location.href;
    const url = new URL(url_string);
    const existingTz = url.searchParams.get("tz");

    if (tzEnabled && tz && !existingTz) {
      url.searchParams.append("tz", tz);
      const decodedUrl = decodeURIComponent(url.toString());
      window.history.pushState(null, "", decodedUrl);
    }
  }
};

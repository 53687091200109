/**
 * A less nonsensical equivalent to DOT GUY
 */
export const EMPTY_PERSONNEL_PLACEHOLDER_ID = 4;
export const LBS_PERSONNEL_ID = 20001;
export const ILLEGAL_PERSONNEL_DISPLAY_NAMES = ["LBS", "."];
export const CONDENSED_DELIMITER = "~!";
export const MILITARY = "military";
export const DEFAULT_TIME_ZONE = "America/Los_Angeles";
export const HISTORY_TIME_ZONE = "America/Los_Angeles"; // Was previously US/Pacific, which is deprecated
export const MRC_DEPARTMENT_ID = -98765;

/*
  Taken form Datastore.js

  Ordering type constants
 */
export const PO_CUSTOM = "custom order";
export const PO_DISPLAY = "display name";
export const PO_LAST = "last name";
export const PO_TIME_CUSTOM = "time then custom order";
export const PO_TIME_DISPLAY = "time then display name";
export const PO_TIME_LAST = "time then last name";
export const PO_TIME_SUBMITTED = "request time submitted";

export const AO_CUSTOM = "custom order";
export const AO_DISPLAY = "name";
export const AO_TIME_CUSTOM = "time then custom order";
export const AO_TIME_DISPLAY = "time then name";

/*
  Taken form Datastore.js

  Action Types
 */
export const ACCEPT_ACTION = "accept";
export const CANCEL_ACTION = "cancel";
export const CANCEL_SWOP_ACTION = "cancel_swop";
export const DECLINE_ACTION = "decline";
export const DELETE_ACTION = "delete";
export const DENY_ACTION = "deny";
export const DETAILS_ACTION = "details";
export const EXCHANGE_ACTION = "exchange";
export const FILL_ACTION = "fill";
export const FINALIZE_ACTION = "finalize";
export const GRANT_ACTION = "grant";
export const MODIFY_ACTION = "modify";
export const MOVE_ACTION = "move";
export const REMOVE_ACTION = "remove";
export const REPLACE_ACTION = "replace";
export const REQUEST_ACTION = "request";
export const SPLIT_SHIFT_ACTION = "split_shift";
export const SUSPEND_ACTION = "suspend";

import { Collection, Model } from "backbone";
import { CSSProperties } from "react";
import { Dispatch } from "redux";
import { PersonnelById } from "@/viewer/types/domain/personnel";
import { AssignmentsById } from "@/viewer/types/domain/assignment";
import {
  BulkToolActions,
  FocusHolidayDateData,
  FocusSlotCallbackData,
  FocusTallySlotCallbackData,
  OpenContextMenuData,
  OpenContextMenuParams,
  UnfocusHolidayDateData,
} from "@/viewer/ui/modules/common/types/callbacks";

export type AppName = "viewer" | "editor" | "reports" | "login";
// Hybrid is standard, grid is columns
export type AppLayout = "hybrid" | "grid" | "list" | "gantt" | "block" | "calendar";

export enum ViewDataType {
  schedule = "schedule",
  combined = "combined",
  request = "request",
}

export enum DisplayDepartmentNames {
  NONE = "Not Displayed",
  DEPARTMENT_NAME = "Department Name",
  DEPARTMENT_COMPACT_NAME = "Department Compact Name",
}

/**
 * This is a container for a variety of static settings from the global context.
 */
export interface SettingsContext {
  // window.LbsAppData.id;
  app: AppName;

  // window.LbsAppData.AppContext.get("blockLength") this is a string on the AppContext
  // Block Length allows you to control the length of the subset of days that are displayed
  // in the merged column.
  blockLength: number;

  // window.LbsAppData.AppContext.get("blockWeekLength") this is a string on the AppContext
  // Block Start Day allow you to control the start of the subset of days that are displayed
  // in the merged column.
  blockStartDay: string;

  // window.LbsAppData.AppContext.get("blockWeekLength") this is a string on the AppContext
  // Block week length determines how many weeks are going to get merged together and displayed in a column.
  blockWeekLength: number;

  // window.LbsAppData.AppContext.attributes.colorMethod
  colorMethod: "none" | "mixed" | "cell" | "row";

  // window.LbsAppData.AppContext.attributes.colorTextMethod
  colorTextMethod: "none" | "colored";

  // window.LbsAppData.AppContext.get("view").theme.data.listColumns
  columnNames: string[];

  // window.LbsAppData.AppContext.attributes.view.theme.data.condenseColumns
  condenseColumnView: boolean;

  // window.LbsAppData.AppContext.attributes.displayedSchedule.id
  displayedScheduleId: number | undefined;

  // window.LbsAppData.AppContext.forceScheduleGroup()
  forceScheduleGroup: boolean;

  // window.LbsAppData.AppContext.forceRequestsGroup()
  forceRequestsGroup: boolean;

  // window.LbsAppData.AppContext.attributes.compactMode == "yes"
  isCompact: boolean;

  // window.LbsAppData.AppContext.attributes.view.theme.data.hidePending == "yes"
  ignorePending: boolean;

  // window.LbsAppData.AppContext.get("view").theme.data.hideBlankRows;
  hideBlankRows: boolean;

  // window.LbsAppData.AppContext.attributes.hideWeekends
  hideWeekends: boolean;

  // LbsAppData.AppContext.attributes.GlobalSettings_hideExpired
  hideExpired: boolean;

  // LbsAppData.User.get("is_admin")
  isUserAdmin: boolean;

  // LbsAppData.User.get("is_super_admin")
  isUserSuperAdmin: boolean;

  // LbsAppData.User.get("customer_id")
  customerId: number;

  // window.LbsAppData.AppContext.attributes.layout
  layout: AppLayout;

  // window.LbsAppData.AppContext.attributes.view.theme.data.personalFilter
  personalFiltersEnabled: boolean;

  // window.LbsAppData.AppContext.attributes.plugins
  plugins: Record<string, string>;

  // window.LbsAppData.AppContext.get("range")
  range: "day" | unknown;

  shouldCountTalliesByPersonnelFilter: boolean;

  // window.LbsAppData.AppContext.attributes.slotOrdering
  slotQuestOrdering: string;

  // window.LbsAppData.User.attributes.tz
  timeZone: string | undefined;

  // window.LbsAppData.User.attributes.LBLiteTimeZoneAwareness
  timeZoneEnabled: boolean;

  // window.LbsAppData.User.get("time_display") === window.LbsAppData.MILITARY
  useMilitaryTime: boolean;

  // window.LbsAppData.AppContext.get("view")
  view: any;

  // window.LbsAppData.AppContext.getViewDataType() => "request" | "schedule" | "combined"
  viewDataType: ViewDataType;

  // window.LbsAppData.AppContext.attributes.view.view_id
  viewId: "me" | "today" | number;

  // window.LbsAppData.AppContext.attributes.view.name
  viewName: string;

  viewTypeRequiresHighlighters: boolean;
}

/**
 * This is a container for the user's permission settings.
 */
export interface PermissionsContext {
  // window.LbsAppData.Helpers.Permissions.CanIChangeAnyonesSchedule();
  canIChangeAnyonesSchedule: boolean;
  // window.LbsAppData.Helpers.Permissions.CanIChangeAnyonesRequests();
  canIChangeAnyonesRequests: boolean;
  // window.LbsAppData.Helpers.Permissions.CanIApproveDenyAnyoneElsesSchedule();
  canIApproveDenyAnyoneElsesSchedule: boolean;
  // window.LbsAppData.Helpers.Permissions.CanIApproveDenyAnyoneElsesRequest();
  canIApproveDenyAnyoneElsesRequest: boolean;
}

/**
 * This is a container for more dynamic settings that may change via user interaction.
 *
 * Recommendation: Move props from SettingsContext to here as it becomes apparent that they sometimes
 * change while people are using the UI!
 */
export interface UIContext {
  // window.LbsAppData.DateManager.attributes.mode
  dateMode: "daily" | "weekly" | "monthly" | "yearly" | "range" | "static" | "anchored" | unknown;

  // window.LbsAppData.AppContext.attributes.displayDepartmentName
  displayDepartmentNames: DisplayDepartmentNames;

  // window.LbsAppData.AppContext.get("mode") === "bulk"
  isBulkMode: boolean;

  // window.LbsAppData.AppContext.attributes.GridSettings_leftColumnType
  leftColumnType: "assignment" | "personnel";

  // window.LbsAppData.AppContext.get("mode")
  viewMode: string;

  showActivityFeed: boolean;

  // window.LbsAppData.AppContext.get("showTimes") == "yes"
  showTimes: boolean;

  showIcons: boolean;

  // window.LbsApData.AppContext.attributes.startOnDay
  startWeekOnDay: "Sat" | "Sun" | "Mon";

  // window.LbsAppData.DateManager.attributes.schedule_start_date
  startDate: Date;

  // window.LbsAppData.DateManager.attributes.schedule_stop_date
  stopDate: Date;

  // window.LbsAppData.DateManager.attributes.start_date
  startDateWithCushion: Date;

  // window.LbsAppData.DateManager.attributes.stop_date
  stopDateWithCushion: Date;

  // window.LbsAppData.DateManager.attributes.currentFragment
  currentFragment: number;

  // window.LbsAppData.AppContext.get("showOriginalPersonnel") === true
  showOriginalPersonnel: boolean;

  // window.LbsAppData.AppContext.get("showOnlyPending") === true
  showOnlyPending: boolean;
}

// Filtering uses this type a lot, so let's make a shortcut
export type FilterMap = { [k: string]: boolean };

export interface FilterContext {
  filteredPersonnel: FilterMap;
  filteredAssignments: FilterMap;
  filteredDepartments: FilterMap;
  filteredTemplates: FilterMap;
}

export interface DataContext {
  personnelById: PersonnelById;
  assignmentsById: AssignmentsById;
}

export interface ScheduleData {
  slots: Collection;
  requests: Collection;
  report: Model;
}

export type ToolEffects = { className?: string; style?: CSSProperties };
export type ToolEffectsBySlotUUID = { [slotUUID: string]: ToolEffects };
export interface ToolContext {
  toolEffectsBySlotUUID: ToolEffectsBySlotUUID;
}

export interface CallbackContext {
  // window.LbsAppData.ToolBox.perform("attach", { component: this });
  // The only handler (BulkSelectTool) for this expects a single arg component, so
  // that it can later force the component to rerender
  //
  // We don't have to worry about this, because the props will change when they change.
  // We may need to do something to mollify BulkSelectTool though
  attachToolbox: (slotUUID: string) => void;

  // window.LbsAppData.ToolBox.perform("reset");
  // window.LbsAppData.ToolBox.perform("attach", slots);
  bulkAttachSlots: (slotUUIDs: string[]) => void;

  // window.LbsAppData.ToolBox.perform("reset");
  // window.LbsAppData.ToolBox.perform("attach", requests);
  bulkAttachRequests: (slotUUIDs: string[]) => void;

  // window.LbsAppData.ToolBox.perform("reset");
  // window.LbsAppData.ToolBox.perform("attach", components);
  bulkAttachAll: (components: { slot: Model }[]) => void;

  // window.LbsAppData.ToolBox.get("currentTool").reset();
  resetCurrentTool: () => void;

  // window.LbsAppData.ToolBox.attributes.availableTools["bulk-select"];
  getBulkSelectTool: () => Model;

  // window.LbsAppData.ToolBox.get("currentTool").getAvailableActions();
  getAvailableActions: () => BulkToolActions;

  // window.LbsAppData.AppContext.openDialog(payload);
  selectAction: (action: string) => void;

  // see getActionKeys in helpers/callbacks.ts
  getActionKeys: () => string[];

  // see getTargetKeys in helpers/callbacks.ts
  getTargetKeys: () => string[];

  // see getSelectedTargets in helpers/callbacks.ts
  getSelectedTargets: () => Model[];

  // window.LbsAppData.AppContext.triggerSwappingMode();
  triggerSwappingMode: () => boolean;

  // window.LbsAppData.AppContext.triggerViewingMode();
  triggerViewingMode: () => void;

  // window.LbsAppData.AppContext.expandFocusedSlot(this.props.slot);
  expandFocusedSlot: (slotUUID: string, openPager?: boolean) => void;

  focusSlot: (data: FocusSlotCallbackData) => void;
  focusTallySlot: (data: FocusTallySlotCallbackData) => void;

  // window.LbsAppData.AppContext.focusHolidayDate
  focusHolidayDate: (data: FocusHolidayDateData) => void;

  // window.LbsAppData.AppContext.unfocusHolidayDate
  unFocusHolidayDate: (data: UnfocusHolidayDateData) => void;

  unFocusSlot: () => void;

  // window.LbsAppData.AppContext.openLoadingDialog
  openDatesChangeLoadingDialog: () => void;

  // see openDateContextMenu in helpers/callbacks.ts
  openDateContextMenu: (settings: SettingsContext, date: Date, target: EventTarget) => void;

  // window.LbsAppData.AppContext.openContextMenu
  // (see also helpers.getSlotContextMenuItems)
  openSlotContextMenu: (
    slotUUID: string,
    data: OpenContextMenuParams,
    settings: SettingsContext,
    dispatch: Dispatch
  ) => void;

  // see openFilterContext in helpers/callbacks.ts
  openFilterContextMenu: (data: OpenContextMenuData) => void;

  openListViewFilterContextMenu: (
    isFiltered: boolean,
    value: string,
    onClick: () => void,
    data: OpenContextMenuParams
  ) => void;
}

export interface ViewerListsContext {
  id: number;
  color: string;
  colorText: string;
}

export interface ViewerFilterContext {
  filter_id: number;
  name: string | null;
  created_by: string | null;
  created_at: Date | null;
  updated_at: Date | null;
  sort_personnel_by: string | null;
  sort_assignments_by: string | null;
  auto_add_personnel: boolean;
  auto_add_assignments: boolean;
  on_departments: number[];
  on_templates: number[];
  on_personnel: ViewerListsContext[];
  on_assignments: ViewerListsContext[];
  on_tallies: number[];
  personnel_expression: string | null;
  assignment_expression: string | null;
}

export interface ViewerThemeContext {
  theme_id: number;
  name: string | null;
  created_by: string | null;
  created_at: Date | null;
  updated_at: Date | null;
  data: ViewerThemeDataContext;
}

export interface ViewerThemeDataContext {
  layout: string;
  GridSettings_leftColumnType: string;
  startOnDay: string;
  colorMethod: string;
  colorTextMethod: string;
  slotOrdering: string;
  showTimes: string;
  dataType: string;
  range: string;
  dt_start: string;
  dt_end: string;
}

export interface ViewerViewContext {
  view_id: number;
  name: string;
  filter_id: number;
  theme_id: number;
  accessible_by: number[];
  created_by: string;
  created_at: Date | null;
  updated_at: Date | null;
  filter: ViewerFilterContext;
  theme: ViewerThemeContext;
}

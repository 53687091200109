import { API_PREFIX } from "@/resources/root/root.resource";
import { getAuthHeaders } from "@/_lib/utils/headers";
import { getParsedResponse, ParsedResponse } from "@/_lib/utils/responses";

export const USER_RESOURCE_ROOT = `${API_PREFIX}/user`;

interface PasswordUpdate {
  readonly password: string;
  readonly new_password: string;
  readonly new_password_confirm: string;
}

export interface PasswordUpdateResponse extends Partial<ParsedResponse<unknown>> {
  message?: string; // Status 200
  Message?: string; // Status 401
}

interface RecoveryEmailUpdate {
  readonly email: string;
  readonly email_confirm: string;
}

export interface RecoveryEmailUpdateResponse extends Partial<ParsedResponse<unknown>> {
  email?: string;
  Message?: string;
}

interface NotificationEmailUpdate {
  readonly email: string;
}

export interface NotificationEmailUpdateResponse extends Partial<ParsedResponse<unknown>> {
  readonly email?: string;
  readonly Message?: string;
}

/*
* Method: POST
  Content: JSON
  Fields: 
  * password: <string>
    new_password: <string>
    new_password_confirm: <string>
   
   Response:
   * 200:
   *  Content: JSON
   *  Fields:
   *    message: <string>
      
     401:
     * Content: JSON
     * Fields:
     *  Message: <string>  //Yes, the same field for 200 and 401 have different casing because why not
* 
*  */

const updateUserPassword = async (opts: PasswordUpdate): Promise<Promise<PasswordUpdateResponse> | Promise<Error>> => {
  const request: () => Promise<Response> = async () =>
    fetch(`${USER_RESOURCE_ROOT}/settings/password`, {
      method: "POST",
      headers: {
        ...getAuthHeaders(),
        "content-type": "application/json",
      },
      body: JSON.stringify(opts),
    });

  try {
    const response = await request();
    const parsedResponse = await getParsedResponse(response);
    const endpointResponse = parsedResponse?.data as PasswordUpdateResponse;

    return { ...parsedResponse, ...endpointResponse };
  } catch (err) {
    return {
      name: "ERROR_UPDATE_RECOVERY_EMAIL_NETWORK_FAILURE",
      message: "Could not update password. (Network Error)",
    } as Error;
  }
};

/*
* Method: POST
* Content: JSON
* Fields: 
*   email <string>
    email_confirm <string>
  Response:
  *   200:
  *   Content: JSON
  *   Fields: email <string>
      
      400:
      * Content: JSON
      * Fields: Message <string>
* */
const updateUserRecoveryEmail = async (
  opts: RecoveryEmailUpdate
): Promise<Promise<RecoveryEmailUpdateResponse> | Promise<Error>> => {
  const request: () => Promise<Response> = async () =>
    fetch(`${USER_RESOURCE_ROOT}/settings/recoveryemail`, {
      method: "POST",
      headers: {
        ...getAuthHeaders(),
        "content-type": "application/json",
      },
      body: JSON.stringify(opts),
    });

  try {
    const response = await request();
    const parsedResponse = await getParsedResponse(response);
    return { ...parsedResponse, email: (parsedResponse?.data as { email: string }).email };
  } catch (err) {
    return {
      name: "ERROR_UPDATE_RECOVERY_EMAIL_NETWORK_FAILURE",
      message: "Could not update recovery email. (Network Error)",
    } as Error;
  }
};

/*
* Method: POST
* Content: JSON
* Fields:
*   email: <string>
  Response:
  * 200:
  * Content: JSON
  *   Fields:
  *     email: <string>
    
    400:
    * Content: JSON
    *   Fields:
    *     Message: <string>
* */

const updateUserNotificationEmails = async (
  opts: NotificationEmailUpdate
): Promise<Promise<NotificationEmailUpdateResponse> | Promise<Error>> => {
  const request: () => Promise<Response> = async () =>
    fetch(`${USER_RESOURCE_ROOT}/settings/notificationemail`, {
      method: "POST",
      headers: {
        ...getAuthHeaders(),
        "content-type": "application/json",
      },
      body: JSON.stringify(opts),
    });

  try {
    const response = await request();
    const parsedResponse = await getParsedResponse(response);

    return {
      ...parsedResponse,
      email: (parsedResponse?.data as { email: string })?.email,
    };
  } catch (err) {
    return {
      name: "ERROR_UPDATE_RECOVERY_EMAIL_NETWORK_FAILURE",
      message: "Could not update notification email(s). (Network Error)",
    } as Error;
  }
};

const userResource = {
  updateUserPassword,
  updateUserRecoveryEmail,
  updateUserNotificationEmails,
};

export default userResource;

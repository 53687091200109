import * as LDClient from "launchdarkly-js-client-sdk";
import ConfigHelper from "../data/ConfigHelper";
import { getUser } from "./viewerApiUtils";
import { LDUserConfig } from "./launchDarklyHelper";

declare const window: { LbsAppData: { User: { attributes: unknown } } };

export interface ILaunchDarklyClient {
  evaluateLDFlag: (flag: string, defaultValue?: unknown) => unknown;
}

export class LaunchDarklyClient implements ILaunchDarklyClient {
  constructor(ldUserConfig: LDUserConfig, callback?: () => void) {
    this.variationFunction = (key, defaultValue: unknown) => {
      return defaultValue;
    };

    this.userConfig = ldUserConfig;
    this.callback = callback;
    this.initialize = this.initialize.bind(this);
    this.evaluateLDFlag = this.evaluateLDFlag.bind(this);
    this.initialize();
  }

  userConfig: LDUserConfig;
  callback?: () => void;
  client: LDClient.LDClient | undefined;
  variationFunction: (key: string, defaultValue?: unknown) => unknown;

  public initialize(): void {
    const user = window.LbsAppData?.User?.attributes ?? getUser();
    if (user) {
      if (ConfigHelper.launch_darkly_client_key) {
        this.client = LDClient.initialize(ConfigHelper.launch_darkly_client_key, {
          key: this.userConfig?.key,
        });

        this.client.on("ready", () => {
          if (this.client) {
            this.variationFunction = this.client.variation;
            if (this.callback) {
              this.callback();
            }
          }
        });
      }
    }
  }

  public evaluateLDFlag(flag: string, defaultValue?: unknown): unknown {
    if (this.variationFunction) {
      return this.variationFunction(flag, defaultValue);
    }

    return defaultValue;
  }
}

import constructSearchCorpus, { SearchCorpusParams } from "@/_lib/utils/constructSearchCorpus";
import { SettingsContext } from "@/viewer/ui/modules/common/types/context";
import { toDate } from "date-fns-tz";

export interface SlotAttributes {
  boundTool: any;
  status: string;
  slot_id: number;
  emp_id: number;
  assign_id: number;
  assign_structure_id: number;
  condensed_structure_id: string;
  assign_plus_structure_id: string;
  work_units: number;
  template: string;
  template_desc: string;
  template_id: number;
  department_id: number;
  slot_date: string;
  start_time: string;
  stop_time: string;
  start_time_utc: string;
  stop_time_utc: string;
  sched_id: number;
  emp_request_id: number | null;
  display_name: string;
  compact_name: string;
  last_name: string | null;
  display_name_override: string;
  assign_display_name: string;
  assign_compact_name: string;
  has_note: boolean;
  available: boolean;
  is_pending: boolean;
  is_granted_request: boolean;
  is_manual_slot: boolean;
  note: string | null;
  pending_emp_id: null | number;
  pending_display_name: string;
  pending_compact_name: string;
  pending_last_name: string | null;
  slot_uuid: string;
  has_change: boolean;
  is_default_time: boolean;
  emp_ptype: number | null;
  assign_atype: number | null;
  original_emp_id: number | null;
  modified_by_emp_id: number | null;
  modified_by_display_name: string | null;
  modified_date: string | null;
  modified_date_utc: string | null;
  decision_note: string | null;
  modif_explanation: string | null;
  request_date?: string;
  request_note: string | null;
  loa_reason_id: string | null;
  loa_reason_name: string | null;
  location_ids: number[];
  location_names: string[];
  is_location_multiple: boolean;
  is_location_editable: boolean;
  slot_history: SlotHistory[];
  pending_info: PendingInfo | null;
  message?: string;
  call_order: number | null;
  parent_assign_structure_id: number | null;
}

export interface SlotHistory {
  timestamp: string;
  text: string;
}

export interface PendingInfo {
  pending_id: number;
  slot_id: number;
  emp_id: number;
  emp_ptype: number;
  modified_by_emp_id: number;
  modified_by_display_name: string;
  loa_reason_id: number | null;
  loa_reason_name: number | null;
  location_ids: number[];
  location_names: string[];
  status: string;
  timestamp: string;
  preswaps: unknown[];
  start_time: string;
  stop_time: string;
  start_time_utc: string;
  stop_time_utc: string;
  is_default_time: boolean;
}

// Cleaned up version of SlotAttributes
export interface Slot {
  [index: string]: Slot[keyof Slot];

  // Common slot/request props
  id: number | "";
  uuid: string;
  date: Date;
  dateString: string;
  startTime: Date;
  startTimeString: string;
  stopTime: Date;
  stopTimeString: string;
  status: string;

  displayName: string;
  displayNameOverride: string;
  compactOrDisplayName: string;
  pendingCompactOrDisplayName: string;
  assignCompactOrDisplayName: string;
  templateDesc: string;
  locationNames: string[];
  locationIds: number[];
  workUnits: number | null;
  departmentId: number | null;
  callOrder: number | null;

  // Flags
  available: boolean;
  isPending: boolean;
  isGrantedRequest: boolean;
  hasNote: boolean;

  // Slot-only attributes
  note: string | null;
  message: string | null;
  pendingInfo: PendingInfo | null;

  // Linked objects
  empId: number | "";
  pendingEmpId: number | "";
  assignStructureId: number | "";
  assignId: number | "";
  condensedStructureId: string;
  templateId: number | "";
  assign_atype: number | null;
  emp_ptype: number | null;
  parentAssignStructureId: number | null;

  keyValueSearchCorpus?: string;
  searchCorpus?: string;

  originalEmpId: number | null;
  reasonName?: string | null;
}

export const cleanSlotAttributes = (
  settings: SettingsContext,
  attrs: Partial<SlotAttributes>,
  searchCorpusParams?: SearchCorpusParams
): Slot => {
  const slot: Slot = {
    id: attrs.slot_id ?? "",
    uuid: attrs.slot_uuid ?? "",
    date: toDate(attrs.slot_date),
    dateString: attrs.slot_date ?? "",
    startTime: toDate(attrs.start_time),
    startTimeString: attrs.start_time ?? "",
    stopTime: toDate(attrs.stop_time),
    stopTimeString: attrs.stop_time ?? "",
    status: attrs.status ?? "",

    displayName: attrs.display_name ?? "",
    displayNameOverride: attrs.display_name_override ?? "",
    compactOrDisplayName: settings.isCompact ? attrs.compact_name ?? "" : attrs.display_name ?? "",
    pendingCompactOrDisplayName: settings.isCompact
      ? attrs.pending_compact_name ?? ""
      : attrs.pending_display_name ?? "",
    assignCompactOrDisplayName: settings.isCompact ? attrs.assign_compact_name ?? "" : attrs.assign_display_name ?? "",
    templateDesc: attrs.template_desc ?? "",
    locationNames: attrs.location_names ?? [],
    locationIds: attrs.location_ids ?? [],
    workUnits: attrs.work_units ?? null,
    departmentId: attrs.department_id ?? null,
    callOrder: attrs.call_order ?? null,
    reasonId: attrs.loa_reason_id ?? null,
    reasonName: attrs.loa_reason_name ?? null,
    available: attrs.available ?? false,
    isDefaultTime: attrs.is_default_time ?? false,
    isPending: attrs.is_pending ?? false,
    isGrantedRequest: attrs.is_granted_request ?? false,
    hasNote: attrs.has_note ?? false,

    note: attrs.note ?? null,
    message: attrs.message ?? null,
    pendingInfo: attrs.pending_info ?? null,

    empId: attrs.emp_id ?? "",
    pendingEmpId: attrs.pending_emp_id ?? "",
    assignId: attrs.assign_id ?? "",
    assignStructureId: attrs.assign_structure_id ?? "",
    condensedStructureId: attrs.condensed_structure_id ?? "",
    templateId: attrs.template_id ?? "",
    assign_atype: attrs.assign_atype ?? null,
    emp_ptype: attrs.emp_ptype ?? null,
    originalEmpId: attrs.original_emp_id ?? null,
    parentAssignStructureId: attrs.parent_assign_structure_id ?? null,
  };

  const searchableAttributes = constructSearchCorpus(slot, searchCorpusParams);

  slot.keyValueSearchCorpus = searchableAttributes?.keyValueSearchCorpus;
  slot.searchCorpus = searchableAttributes?.searchCorpus;

  return slot;
};

export interface SlotTime {
  hours: number;
  minutes: number;
  date: Date;
}

export type SlotById = Record<string, Slot>;

/* eslint-disable @typescript-eslint/no-var-requires */
import { buildQueryParams, getQueryParamsFromSearchString } from "@/_lib/utils/queryParams";
import { AppDispatch, Thunk } from "@/modules/types";
import { request } from "@/_lib/utils/fetch";
import * as errorReporter from "@/_lib/utils/errorReporter";
import ConfigHelper from "@/_lib/data/ConfigHelper";
import { stringifyQuery } from "@/_lib/utils/queryString";
import { AppContext, getAppContext } from "@/_lib/utils/urls";
import ApiConfig from "@/_lib/data/ApiConfig";
import docCookies from "@/_lib/utils/cookies";

import { getBrowserTimezone } from "@/viewer/utils/timezones";
import { tranformSingleViewToOldViewer, transformViewsToOldViewer } from "@/_lib/utils/viewUtils";
import {
  fetchViewerAPILBAPIPayload,
  getReportData,
  getUser,
  stringifyViewerAPIResponse,
  getToggleViewerApiFlag,
  fetchUser,
} from "@/_lib/utils/viewerApiUtils";
import logging from "@/_lib/utils/logging";

export type Action = { type: "app/init" } | { type: "app/logout"; nextUrl: string };

const TOKEN_TIMEOUT = 604800;

const getPayloadApiUrl = (context: AppContext): string => {
  const path = window.location.pathname.toString();
  if (context === "dashboard") {
    // the url params are added here because express needs to compute the redirect url using the origin
    // and origin_hash params. This is for cases when we are using swop links from and email etc.
    // Dashboard is the only endpoint that does this.
    return "/api/v1/dashboard" + window.location.search;
  }

  const queryParams = { tz: getBrowserTimezone(), ...(getQueryParamsFromSearchString(window.location.search) ?? {}) };
  const searchString = buildQueryParams(queryParams);

  return `/api/v1${path}?${searchString}`;
};

const getRedirectQueryString = (): string => {
  const query: { [k: string]: string } = { source: "access", dest: "app", noRedirect: "true" };

  query.origin = window.location.href.split("#")[0].replace(/\/$/, "");

  if (window.location.hash) {
    query.origin_hash = window.location.hash.replace("#/", "");
  }

  return stringifyQuery(query);
};

export const fetchInitPayload = async (dispatch: AppDispatch, context: AppContext) => {
  // TODO: Fetch payload, auth, handle errors, etc.

  let url = getPayloadApiUrl(context);

  try {
    let resp;
    if (url.includes("viewer")) {
      await fetchUser();
      const user = getUser();
      if (await getToggleViewerApiFlag(user)) {
        const host = ApiConfig.getPrefix();
        url = `${host}/viewerapi`;
        resp = await fetchViewerAPILBAPIPayload(url);
      } else {
        resp = await request(url);
      }
    } else {
      resp = await request(url);
    }
    if (resp) {
      if (resp.status >= 500) {
        errorReporter.reportError(
          { colno: 0, error: resp.statusText, lineno: 0, message: resp.statusText } as ErrorEvent,
          resp.url,
          "Actions - Fetch Init Server Error"
        );
      }

      if (resp.redirected || resp.status === 401) {
        if (ConfigHelper.show_lblite_login === "false") {
          const host: string | undefined = ConfigHelper.getURL("host_unity");
          const queryString = getRedirectQueryString();
          const url = `${host}?${queryString}`;

          window.location.assign(url);
          return;
        }

        if (window.location.pathname.startsWith("/login")) {
          docCookies.removeItem("USER", "/");
          return { logged_in: false };
        }

        window.location.assign(`/login/?origin=` + encodeURIComponent(window.location.href.replace(/\/$/, "")));
        return;
      }

      if (resp.headers.get("Content-Type")?.startsWith("application/json")) {
        let body = await resp.json();
        if (body?.user) {
          const loggedInUser = url.includes("viewerapi") ? body.user : JSON.parse(body.user);
          if (loggedInUser) {
            docCookies.setItem(
              "USER",
              url.includes("viewerapi") ? JSON.stringify(body?.user) : body?.user,
              TOKEN_TIMEOUT,
              "/"
            );
          }
        }
        if (url.includes("viewerapi")) {
          const user = getUser();
          body.view_context = tranformSingleViewToOldViewer(body?.view_context, user?.emp_id);
          body.views = transformViewsToOldViewer(body.views, user?.emp_id);
          body = stringifyViewerAPIResponse(body);

          const reportData = await getReportData(body);
          if (reportData) {
            body.report_data = reportData;
          }
        }

        // if express computes a redirect url and doesn't return payload data for a viewer (for example) then we need
        // to redirect to that url. Otherwise, we're just getting payload data back.
        if (body.url) {
          window.location.assign(body.url);
        } else {
          return body;
        }
      } else if (resp.headers.get("Content-Type")?.startsWith("text/html")) {
        document.write(await resp.text());
      }
    }
  } catch (e) {
    logging.error(e as Error);
    errorReporter.reportError(e as ErrorEvent, window.location.href, "Actions - Fetch Init");
    // eslint-disable-next-line no-console
    console.error(e);
    return;
  }
};

const initializeViewer = (payload: any) => {
  const Datastore = require("../../viewer/data/Datastore");
  const LbsAppData = Datastore(payload);

  (window as any).LbsAppData = LbsAppData;
  LbsAppData.init();
};

const initializeEditor = (payload: any) => {
  const Datastore = require("../../editor/data/Datastore");
  const LbsAppData = Datastore(payload);
  (window as any).LbsAppData = LbsAppData;

  if (payload.schedule_context) {
    LbsAppData.init();
  }
};

const initializeReports = (payload: any) => {
  const Datastore = require("../../reports/data/Datastore");
  const LbsAppData = Datastore(payload);
  (window as any).LbsAppData = LbsAppData;

  LbsAppData.init();
};

const initializeDashboard = (payload: any) => {
  // When we try to go to the dashboard, if we get an object back that is { logged_in: false } that means we haven't
  // logged in yet, so just smash it onto the window and let it ride
  const Datastore = require("../../login/data/Datastore");
  const LbsAppData = Datastore(payload);
  (window as any).LbsAppData = LbsAppData;

  if (payload && payload.logged_in) {
    LbsAppData.init();
  }
};

export const initialize = (): Thunk => async (dispatch) => {
  // console.clear();
  if ((window as any).payload) {
    // Already initialized, don't do it again.
    // eslint-disable-next-line no-console
    console.error("Already initialized");
    return;
  }

  const context = getAppContext();

  // const fetchStart = new Date().getTime();
  const payload = await fetchInitPayload(dispatch, context);
  // console.log("Fetch:", new Date().getTime() - fetchStart);

  if (!payload) return;

  // Still need to store the payload on window
  (window as any).payload = payload;

  // initialize the Datastore (and window.LbsAppData)
  // const timerStart = new Date().getTime();

  switch (context) {
    case "viewer":
      await initializeViewer(payload);
      break;
    case "editor":
      await initializeEditor(payload);
      break;
    case "login":
      await initializeDashboard(payload);
      break;
    case "dashboard":
      await initializeDashboard(payload);
      break;
    case "reports":
      await initializeReports(payload);
      break;
  }

  // console.log("Init: " + (new Date().getTime() - timerStart));
  dispatch({ type: "app/init" });
};

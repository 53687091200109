import React from "react";

import { AppName, SettingsContext } from "@/viewer/ui/modules/common/types/context";
import { User } from "@/viewer/types/domain/user";

import LogoMenu from "./logomenu/LogoMenu";

interface Props {
  appName: AppName;
  isScheduled: boolean;
  settings: SettingsContext;
  user: User;
}

const LogoMenuContainer = (props: Props): JSX.Element => {
  const { appName, isScheduled, settings, user } = props;
  const [showMenu, setShowMenu] = React.useState(false);

  const handleMouseEnter = () => {
    if (!showMenu) {
      setShowMenu(true);
    }
  };

  const handleMouseLeave = () => {
    if (showMenu) {
      setShowMenu(false);
    }
  };

  const handleMouseClick = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div
      id="logo-tile-icon"
      data-cy="TBLogoMenu"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleMouseClick}
      onTouchEnd={handleMouseLeave}
    >
      <LogoMenu active={showMenu} appName={appName} isScheduled={isScheduled} settings={settings} user={user} />
    </div>
  );
};

export default LogoMenuContainer;

import { LaunchDarklyClient } from "./launchDarklyClient";
import { LDFlagEnums } from "@/_lib/constants/LDFlagEnums";
import { ViewerViewContext } from "@/viewer/ui/modules/common/types/context";
import { User } from "@sentry/browser";
import moment, { Moment } from "moment";
import _ from "lodash";
import StaghornConfig from "@/_lib/data/StaghornConfig";
import logging from "@/_lib/utils/logging";
import { getTokenFromRefreshToken } from "./viewerApiUtils";
import { StaghornReportResponse } from "@/viewer/types/domain/report";

export const findStartEndPoints = function (
  tzdata: number,
  view: ViewerViewContext,
  user: User,
  anchor?: Date
): { start: Moment; end: Moment } {
  // infantile logic... but think it should be good enough to handle the whole "day ahead"
  // problem with somebody in Australia (for example)
  let start = moment();
  let end = moment().add(1, "day");

  // masquerade as a different date (if passed in via the query string) -- i.e. someone is loading a view after moving the dates
  const anchorDate = anchor ? moment(anchor) : moment().utcOffset(tzdata);

  // create a base date that handles the timezone offset (set via client cookies)
  //var baseDate = moment(anchorDate).utc().utcOffset(this._params.tzdata);
  const baseDate = moment(anchorDate);

  // should make this key in on range eventually
  if (view.theme.data.layout === "list") {
    if (view.theme.data.range === "week") {
      // grab the range from today to today+7 days
      start = moment(baseDate).startOf("day");
      if (user.parameters.LBLiteTimeZoneAwareness) {
        end = moment(baseDate).add(7, "days").endOf("day");
      } else {
        end = moment(baseDate).add(6, "days").endOf("day");
      }
    } else if (view.theme.data.range === "day") {
      // grab the range from today to today+7 days
      start = moment(baseDate).startOf("day");
      end = moment(baseDate).endOf("day");
    } else if (view.theme.data.range === "specific") {
      // printing takes start/end from url parameters
      start = moment(view.theme.data.dt_start, "YYYYMMDD").startOf("day");
      end = moment(view.theme.data.dt_end, "YYYYMMDD").endOf("day");
    }
  } else {
    if (view.theme.data.range === "day") {
      // just grab today's data
      start = moment(baseDate).startOf("day");
      end = moment(baseDate).endOf("day");
    } else {
      // grab the current month
      if (view.theme.data.startOnDay === "Mon") {
        // start of isoWeek is a monday
        start = moment(baseDate).startOf("month").startOf("isoWeek").startOf("day");
        end = moment(baseDate).endOf("month").endOf("isoWeek").endOf("day");
      } else {
        // start of week is a sunday
        start = moment(baseDate).startOf("month").startOf("week").startOf("day");
        end = moment(baseDate).endOf("month").endOf("week").endOf("day");
      }
    }
  }

  return { start, end };
};

export const createDateIds = function (start: Moment, end: Moment): string[] {
  const ret = [];
  const rollingDate = moment(start);
  for (let i = 0; i < end.diff(start, "day"); i++) {
    ret.push(rollingDate.format("YYYYMMDD"));

    rollingDate.add(1, "day");
  }

  return ret;
};

export const getTallyReportSkeleton = (
  tzdata: number,
  view: ViewerViewContext,
  user: User,
  anchor?: Date
): { _definition: any; _composition: any } => {
  const dates = findStartEndPoints(tzdata, view, user, anchor);

  const _definition = {
    properties: {
      data: {
        mode: "adhoc",
        start_date: {
          type: "specific",
          value: dates.start.format("YYYY-MM-DD"),
        },
        end_date: {
          type: "specific",
          value: dates.end.format("YYYY-MM-DD"),
        },
        report_type: "schedule",
        count_original: false,
        include_history: false,
        pub_admin: false, // possible overwrite
        r_granted: true,
        r_pending: true,
        r_denied: false,
        logged_in_user: false,
        period_type: "current",
        split: "none",
      },
      table: {
        x: "date",
        y: "tally",
        x_dim: "none",
        y_dim: "none",
        show_totals: false,
      },
    },
    tallies: [] as number[], // overwrite this
    personnel: [] as number[], // shouldn't be necessary
    templates: [] as number[], // overwrite this
    departments: [] as number[], // overwite this
  };

  const _composition = {
    nodes: {
      0: {
        type: "tally_counts",
        def: {
          mode: "adhoc",
          start_date: dates.start.format("YYYYMMDD"),
          end_date: dates.end.format("YYYYMMDD"),
          report_type: "schedule",
          count_original: false,
          include_history: false,
          pub_admin: false, // possible overwrite
          r_granted: true,
          r_pending: true,
          r_denied: false,
          logged_in_user: false,
          period_type: "current",
          split: "none",
          emp_ids: [] as number[], // don't think you need this..
          tally_ids: [] as number[], // overwrite this
          template_ids: [] as number[], // overwrite this
          department_ids: [] as number[], // overwrite this
        },
      },
      1: {
        type: "table",
        def: {
          title: "undefined",
          x: {
            obj: "date",
            dim: "none",
            ids: createDateIds(dates.start, dates.end),
          },
          y: {
            obj: "tally",
            dim: "none",
            ids: [] as string[], // overwrite this
          },
        },
      },
    },
    paths: {
      0: [0, 1],
    },
  };

  return { _definition, _composition };
};

const defineStaghornReportsData = (
  tzdata: number,
  view: ViewerViewContext,
  user: User,
  isTalliesRequestCombinedViews: boolean,
  anchor?: Date
) => {
  const formData = getTallyReportSkeleton(tzdata, view, user, anchor);
  formData._composition.nodes["0"].def.emp_ids = view?.filter?.on_personnel?.map(function (p) {
    return p.id;
  });
  formData._definition.personnel = view?.filter?.on_personnel?.map(function (p) {
    return p.id;
  });
  formData._definition.tallies =
    formData._composition.nodes["0"].def.tally_ids =
    formData._composition.nodes["1"].def.y.ids =
      _.map(view.filter.on_tallies, "id");
  formData._definition.templates = formData._composition.nodes["0"].def.template_ids = view.filter.on_templates;
  formData._definition.departments = formData._composition.nodes["0"].def.department_ids = view.filter.on_departments;
  formData._definition.properties.data.pub_admin = formData._composition.nodes["0"].def.pub_admin = user.is_admin;
  if (isTalliesRequestCombinedViews) {
    const reportType = view.theme.data.dataType;
    formData._definition.properties.data.report_type = formData._composition.nodes["0"].def.report_type = reportType;
  }
  return formData;
};

export const fetchStaghornReportData = async (data: {
  _definition: unknown;
  _composition: unknown;
}): Promise<StaghornReportResponse | undefined> => {
  const url = StaghornConfig.getPrefix() + "/report";
  const token = await getTokenFromRefreshToken();
  if (!token) {
    return undefined;
  }

  try {
    if (url) {
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (res.ok) {
        const reportData = await res.json();
        return reportData;
      }
    }
  } catch (e) {
    logging.error(`failed to load staghorn report data due to ${e}`);
  }
};

export const loadReportData = async (
  ldClient: LaunchDarklyClient,
  view: ViewerViewContext,
  user: User,
  timezone: string,
  dt: string
): Promise<StaghornReportResponse | undefined> => {
  const isTalliesRequestCombinedViews = ldClient.evaluateLDFlag(
    LDFlagEnums.Lv6823TalliesInRequestCombinedViews,
    false
  ) as boolean;
  if (!isTalliesRequestCombinedViews && ["request"].indexOf(view.theme.data.dataType) > -1) {
    return undefined;
  } else if (view.filter?.on_tallies?.length) {
    const tzdata = timezone ? moment().tz(timezone).utcOffset() : -480;
    const anchorDate = dt?.length ? moment(dt).tz(timezone).toDate() : undefined;
    const formData = defineStaghornReportsData(tzdata, view, user, isTalliesRequestCombinedViews, anchorDate);
    const reportData = (await fetchStaghornReportData(
      formData as { _composition: unknown; _definition: unknown }
    )) as StaghornReportResponse;
    if (reportData) {
      return reportData as StaghornReportResponse;
    }
    return undefined;
  } else {
    return undefined;
  }
};

import * as React from "react";
import { SlotOrRequest } from "@/viewer/ui/modules/common/types";
import classnames from "@/_lib/utils/classnames";
import { isSlot } from "@/viewer/utils/domain/slotquests";
import { SettingsContext } from "@/viewer/ui/modules/common/types/context";
import { formatHumanizedDate, formatLocalTime } from "@/viewer/utils/dateFormatters";
import { DepartmentsById } from "@/viewer/types/domain/department";
import { RequireIntersection } from "@/_lib/utils/typeUtils";

import { MASTER_REQUEST_CALENDAR_ID } from "@/_lib/constants/globals";

import "./SlotPopup.scss";

interface Props {
  settings: SettingsContext;
  slots: SlotOrRequest[];
  position?: { x: number; y: number } | null;
  departmentsById?: DepartmentsById;
}

const TagList = ({ settings, slots }: RequireIntersection<Props, "departmentsById">) => {
  const tagItems: JSX.Element[] = [];

  // no tags on block views
  if (settings.layout !== "block" && slots.length > 0) {
    const slot = slots[0];
    // squashing the slot/request differences to be analogous to each other for now
    if (slot.message) {
      tagItems.push(
        <div key={"note"} className={"tag note"}>
          {slot.message}
        </div>
      );
    } else if (isSlot(slot) && slot.hasNote) {
      tagItems.push(
        <div key={"note"} className={"tag note"}>
          {slot.note}
        </div>
      );
    }

    if (isSlot(slot) && slot.isGrantedRequest) {
      tagItems.push(
        <div key="request" className="tag request">
          Request
        </div>
      );
    } else if (slot.status === "granted") {
      tagItems.push(
        <div key="request" className="tag request">
          Granted
        </div>
      );
    }

    if (isSlot(slot) && (slot.isPending || slot.status === "pending")) {
      const preswaps = slot.pendingInfo?.preswaps ?? [];
      const tagText = preswaps.length > 0 ? "Swaportunity" : "Pending";

      tagItems.push(
        <div key="pending" className="tag pending">
          {tagText}
        </div>
      );
    }

    // Request pending
    if (!isSlot(slot) && slot.status === "pending") {
      tagItems.push(
        <div key="pending" className="tag pending">
          Pending
        </div>
      );
    }

    if (slot.status === "denied") {
      tagItems.push(
        <div key="denied" className="tag denied">
          Denied
        </div>
      );
    }

    if (slot.status === "deleted") {
      tagItems.push(
        <div key="deleted" className="tag deleted">
          Deleted
        </div>
      );
    }
  }

  if (tagItems.length) {
    return <div className="tags">{tagItems}</div>;
  }

  return null;
};

const SlotPopUp = (props: Props): JSX.Element => {
  const { slots, settings, departmentsById, position } = props;

  const classes = classnames("SlotPopUp", "slotpopup", {
    hidden: false,
  });

  if (slots.length < 1) {
    return <></>;
  }

  const slot = slots[0];
  const personnelText = slot.displayName;
  const assignmentText = slot.assignCompactOrDisplayName;
  const customDisplayText = isSlot(slot) ? slot.displayNameOverride : null;
  const personnelCustomDisplayText = customDisplayText ? personnelText + " [" + customDisplayText + "]" : personnelText;

  let departmentName = "";

  if (departmentsById && slot) {
    departmentName =
      slot?.departmentId !== MASTER_REQUEST_CALENDAR_ID
        ? (departmentsById[slot?.departmentId as number] ?? {})?.name
        : "";
  }

  let dateString, timeString;

  if (slots.length > 1) {
    const lastSlot = slots[slots.length - 1];

    dateString = (
      <span className="date">
        {formatHumanizedDate(settings, slot.date)} &mdash;
        <span className="date">{formatHumanizedDate(settings, lastSlot.date)}</span>
      </span>
    );
  } else {
    dateString = <span className="date">{formatHumanizedDate(settings, slot.date)}</span>;
  }

  if (slots.length === 1) {
    timeString = `${formatLocalTime(settings, slot.startTime)} - ${formatLocalTime(settings, slot.stopTime)}`;
  }

  return (
    <div
      className={classes}
      style={
        position
          ? {
              position: "absolute",
              top: `${position?.y ?? 0}px`,
              left: `${position?.x ?? 0}px`,
            }
          : undefined
      }
    >
      <div className="position-reset">
        <div className="details">
          <div className="date-props">{dateString}</div>
          <div className="text">
            <div className="assignment-container">
              <span className="assignment-text">{assignmentText}</span>
              <span className={"assignment-department-name"}>{departmentName}</span>
              <span className="time">{timeString}</span>
            </div>
            <div className="personnel-container">
              <span className="personnel-text">{personnelCustomDisplayText}</span>
            </div>
          </div>
        </div>
      </div>
      <TagList settings={settings} slots={slots} />
    </div>
  );
};

export default SlotPopUp;

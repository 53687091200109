import React from "react";
import ReactDOM from "react-dom";
import { reportError } from "@/_lib/utils/errorReporter";

import "@/_lib/fontawesome/_fontawesome.scss";

// Load polyfills
import "core-js/stable";
import "regenerator-runtime/runtime";
import "whatwg-fetch";

import App from "./App";

import ConfigHelper from "@/_lib/data/ConfigHelper";
import ReactGA from "react-ga4";

// Initialize ambient globals
const initGlobals = (globalContext: any) => {
  globalContext.$ = require("jquery");
  globalContext.Backbone = require("backbone");
  globalContext.Backbone.$ = $;
  globalContext._ = require("underscore");
  globalContext.moment = require("moment");

  globalContext.onerror = (event: ErrorEvent) => {
    reportError(event, window.location.href, "Login - Index");
  };

  if (globalContext.ga && ConfigHelper.ga_key !== undefined) {
    // Instantiate google analytics
    globalContext.ga("create", ConfigHelper.ga_key, "auto");
  } else {
    // Put in a stub for dev
    globalContext.ga = function (...args: any[]) {
      // eslint-disable-next-line no-console
      console.log("GA", args);
    };
  }
};

initGlobals(window);

ReactGA.initialize("G-6YZTCG6XM3");

// Mount app

const appContainer = document.getElementById("content");

window.onload = function () {
  ReactDOM.render(React.createElement(App), appContainer);
};

(window as any).timer = 0;

import { convertDelimitedKVStringToObject } from "./transformers/string";

export interface QueryParams {
  [key: string]: string | number | boolean;
}

const isEmpty = (value: string | number | boolean) => {
  return value === "" || value === null || value === undefined;
};

export const buildQueryParams = (params: QueryParams): string => {
  return Object.keys(params)
    .filter((key) => {
      return !isEmpty(params[key]);
    })
    .map((param) => param + "=" + params[param])
    .join("&");
};

// Convert search (query string) received from location.search to an object of query params
export const getQueryParamsFromSearchString = (searchString: string): QueryParams | undefined => {
  if (!searchString) return;

  let cleanString = searchString.trim();

  // eslint-disable-next-line no-magic-numbers
  if (searchString.startsWith("?")) cleanString = searchString.slice(1);

  return convertDelimitedKVStringToObject(cleanString, "&", "=");
};

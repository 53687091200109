// Manager to handle communication with Google Analytics

var moment = require("moment");
var _ = require("underscore");
var $ = require("jquery");

var AnalyticsHelper = Backbone.Model.extend({
  defaults: {
    active: true,
    eventValueTimerStart: undefined,
  },

  initialize: function () {
    // don't send analytics if we're on the dev server
    if (window.top.location.hostname == "localhost") {
      //this.attributes.active = false;
    }
  },

  /* publics */
  sendPageview: function (page, title) {
    // if available, set the tracker to this page so they trickle down to any subsequent events
    if (page) {
      window.ga("set", {
        page: page,
        title: title,
      });
    } else {
      // don't want to do this anymore (shouldn't be hit)
      // we're closing something, so push the layout values
      //if (window.LbsAppData.AppContext.attributes.layout == 'grid') {
      //	// explorer
      //	window.ga('set', {
      //		'page': '/explorer',
      //		'title': 'Explorer Layout'
      //	});
      //} else {
      //	// standard
      //	window.ga('set', {
      //		'page': '/standard',
      //		'title': 'Standard Layout'
      //	});
      //}
    }

    if (this._isActive()) {
      // track a pageview
      window.ga("send", "pageview");
    }
  },
  sendEvent: function (action, label, value) {
    if (this._isActive()) {
      var category = window.LbsAppData.User ? window.LbsAppData.User.attributes.customer_id.toString() : "No Client";
      window.ga("send", {
        hitType: "event",
        eventCategory: category, // always set category to the client id
        eventAction: action,
        eventLabel: label,
        eventValue: value,
      });
    }
  },
  sendTiming: function (action, value, label) {
    if (this._isActive()) {
      var category = window.LbsAppData.User ? window.LbsAppData.User.attributes.customer_id.toString() : "No Client";
      // submit a timing event to ga
      window.ga("send", {
        hitType: "timing",
        timingCategory: category,
        timingVar: action,
        timingValue: value,
        timingLabel: label,
      });
    }
  },

  resetEventValueTimer: function () {
    this.set({
      eventValueTimerStart: new Date(),
    });
  },

  /* privates */
  _isActive: function () {
    return this.attributes.active;
  },
});

module.exports = AnalyticsHelper;
